import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                HMS: 'GenieHMS',
                Geniemove: 'GenieMove',
                Customize: 'Customize',
                welcome: 'Welcome',
                Home: 'Home',
                AboutUs: 'About us',
                Product: 'Product',
                Technology: 'Technology',
                Industry: 'Industry',
                Solutions: 'Customize',
                Contact: 'Contact',
                translationText: '中',
                Address: 'Address',
                Email: 'Email',
                Phone: 'Phone',
                Getintouch: 'Get In Touch',
                CompanyName: 'Genieland Company Limited',
                CompanyAddress: 'Unit 318A, 3/F., InnoCentre, 72 Tat Chee Avenue, Kowloon Tong, Hong Kong',
                AboutUs_Text1:
                    'Genieland uses AI solutions and technology to provide physical and mental health solutions, cognitive training, psychological counselling, etc. which can assist social welfare organizations connect with service recipients.   Genieland aims to enhance the public’s awareness of self-health management, strengthen recovery capabilities and prevent chronic physical illness.',
                AboutUs_Text2:
                    'We advocate a holistic approach and evolve from the traditional model to a systematic all-round organizational resources and rehabilitation management platform, aims to provide solutions to organizations to enhance and improve health management and tele-rehabilitation services, and look forward to allowing users to do training "Anytime and Anywhere".    We are also dedicated to facilitating the organization to use systemic resource analysis solutions to manage and allocate their resources effectively.',
                AboutUs_Text3:
                    'Other than branded Products/Platform, Genieland provides customized Apps development services for organizations by using proper new technology, and with solid experience to serve with SME, Smart City Advocacy Organization, Hospitalization and Rehabilitation Industry.',
                Solutions_Title: 'Genieland is professional in Mobile App Development',
                Solutions_Part2:
                    'Genieland is experienced on performing custom app development.      And we can develop apps specifically tailored to the needs of the business of our customer.',
                Solutions_Part2_Title: 'Customize',
                SolutionsBody1_Title: 'Customization',
                SolutionsBody1_Text:
                    'Genieland execute practical applications learning,  and using other emerging technologies to develop APPs,  and aim to facilicate and enhance the function of the existing solution or system of the particular customer. ',
                SolutionsBody2_Titile: 'Off-cloud',
                SolutionsBody2_Text:
                    'Genieland is experienced on performing custom Native app development. And we can develop apps specifically tailored to the needs of the business of our customer. ',

                LandingProduct: 'Our product',
                LandingContact: 'Our product',
                LandingContactText: 'Genieland provides various human-centric wellness activities and AI solutions to...',
                Genie_title: 'Meet our AI guided wellness practice tool - genie',
                Genie_body1:
                    'genie provides various mild to moderate fun practices and AI-guided exercises for people who lack the motivation to move and constantly experience pains or discomforts from long hours of sitting (sedentary lifestyle).',
                Genie_body2: '',
                Genie_BoxTilte1: 'simple',
                Genie_BoxBody1: 'short, fun exercise guided by visual and voice',
                Genie_BoxTilte2: 'real-time',
                Genie_BoxBody2: 'estimation of',
                Genie_BoxsubBody2: 'your',
                Genie_Boxsub2Body2: 'posture',
                Genie_BoxTitle3: 'reward',
                Genie_BoxBody3: 'yourself a genie coin for a daily 10 mins practice',
                Genie_BoxTitle4: 'self-monitor',
                Genie_BoxBody4: 'wellness status with progress reports & graphs',
                Move_Tilte:
                    'GenieMove platform is an “Omnidirectional Organization Resource & Tele-Rehabilitation Service Management Platform”:',
                Move_Body1:
                    '• Assist therapists to enhance interaction with clients, track performance progress and manage rehab cases remotely,',
                Move_Body2:
                    '• Therapists can assign exercises through the platform and provide feedback based on the exercise performance of the rehab person or the elderly, and can manage a number of clients at the same time,',
                Move_Body3:
                    '• Rehab persons or the elderly can receive personalized support services anytime and anywhere to maintain their physical and mental health,',
                Move_Body4: '• Diversified application of APP remotely, can be used for internal training or team building activities,',
                Move_Body5:
                    '• Applicable to different activities and multi-purpose management, such as "equipment operation and management" and "document control and management".',
                Move_BoxTitle1: 'remotely',
                Move_BoxBody1: 'provide remotely trainings',
                Move_BoxTitle2: 'portal',
                Move_BoxBody2: 'manage the daily operation',
                Move_BoxTitle3: 'at home',
                Move_BoxBody3: 'with video guiding practice',
                Move_BoxTitle4: 'tracking',
                Move_BoxBody4: 'exercise progress and pattern',
                Move_BoxTitle5: 'RESOURCES MANAGEMENT',
                Move_BoxBody5: 'SYSTEMIC ANALYSING AND MANAGING ORGANIZATIONAL RESOURCES',
                MoveTitle: 'GenieMove',
                MoveTitle2: 'Omnidirectional Organization Resource and ',
                MoveTitle3: 'Tele-Rehabilitation Service Management Platform',
                MoveBody1:
                    '• Assist therapists to enhance interaction with clients, track performance progress and manage rehab cases remotely,',
                MoveBody2:
                    '• Therapists can assign exercises through the platform and provide feedback based on the exercise performance of the rehab person or the elderly, and can manage a number of clients at the same time,',
                MoveBody3: ' ',
                MoveBody4:
                    '• Rehab persons or the elderly can receive personalized support services anytime and anywhere to maintain their physical and mental health,',
                MoveBody5: '• Diversified application of APP remotely, can be used for internal training or team building activities,',
                MoveBody6:
                    '• Applicable to different activities and multi-purpose management, such as "equipment operation and management" and "document control and management".',
                More_info: 'More info',
                HMSTitle: 'GenieHMS - Health & Wellness Training Services',
                HMSBody1: 'Products will be launched in the 4th quarter of 2023',
                HMSBody2: '•AI Integrated Health & Wellness Monitoring System - Using Genieland Core Technology',
                HMSBody3: '•Facilitate participation in fitness or rehabilitation exercise',
                HMSBody4: '•Can provide interactive, online or offline communication platform',
                HMSBody5: '•AI challenges can increase the fun & continuity of the course of treatment',
                HMSBody6: '•Strong system compatibility, iOS and Android programs can also be used',
                HMSBody7:
                    '•Interactive intelligent Training station - adding intelligent activity facilities to the installed center can enhance work efficiency and manpower deployment.',
                HMSBody8:
                    '•Institutional therapists and related personnel can remotely manage the progress of users and other promotional activities of the center through the network system. ',
                HMSBody9: '•This product can be linked/connected with the [ GenieMove ] platform.',
                MoreTech: 'Products using the techology :  ',
                More: 'Customized Apps : ',
                CustomizeCard1_Title: 'genieMove(Customize)',
                CustomizeCard1_Body: 'Cooperate with different agencies and produce customized content...',
                CustomizeCard2_Title: 'GenieHMS (Customize)',
                CustomizeCard2_Body:
                    'Artificial intelligence integrated health and wellness monitoring system, supporting off-cloud model customization functions...',
                CustomizeCard3_Title: 'Embrace Health',
                CustomizeCard3_Body: 'Record your health status and know your own TCM physique, and master your own health changes...',
                IndustryBody: 'Apps development provider for hospitalization, healthcare and rehabilitation service industry',
                Industry1_Title: 'NGO',
                Industry1_Body:
                    'to provide customized and cost-optimized apps in order to keep the app rolling out cost effectiveness. These apps will be best fitted for the uniqueness of operations of each NGOs and social group, whose aim is to serve on educational, healthcare, and much more.',
                Industry2_Title: 'Hospital',
                Industry2_Body:
                    'Mission on creating mobile apps for hospitals that assist and address the resource pressure, including human resource, of patient-centered hospitals, and facilitate enhancing the efficiencies of legacy hospitalization systems.    ',
                Industry2_Body2:
                    'We know that the key to creating a successful mobile app for hospitals is empathy.   As app developers, we know how to design and develop hospital mobile apps with a consciousness of how to let the users feel confident and secure to use customized solutions.  And in pursuit of easing clinical communication between providers and patients, as well as improve the management of hospital workflows.',
                Industry3_Title: 'Rehabilitation Service',
                Industry3_Body:
                    'By using AI and related technology, the company provides physical and mental health solutions that assist rehabilitation institutions connect with service patients, aiming to enhance the publics awareness of self-health management, strengthen rehabilitation capabilities and prevent chronic diseases.',
                Industry3_Body2:
                    'Assist therapists to enhance interaction with patients, progress tracking and management by mobile apps. Facilitate the resource management of the organization.',
                Technology_title: 'Genieland Core Technology',

                Technology_P2_Title: 'Human Pose Estimation & Behavior Analysis',
                Technology_P2_Body:
                    'Human Pose Estimation and Behavior Analysis are patented AI technologies developed by Genieland since 2017, which are rarely used in traditional applications on the market.  It is a computer vision algorithm that only needs to collect data through the camera of a mobile device (such as a smartphone), detect human body data through images and videos, and estimate 17 important joints of the human body (such as eyes, ears, shoulders...), and each important joint position has a corresponding score, and its accuracy is greater than 85%.',
                Technology_P2_Body2:
                    "The data obtained from the exercise can be combined with the comprehensive report to calculate the user's posture, holding time and balance, analyze it from multiple angles, and then provide a progressive comprehensive report and corresponding feedback through our domain knowledge graph, which can guide the user to perform other tasks personal daily practice.",
                Technology_Title: 'Genieland Core Technology',
                AppBarGenie: 'AI guided wellness practice tool',
                AppBarGenieBody: 'genie provides various mild to moderate fun practices and ...',
                AppBarHMS: 'Health & Wellness Training Services',
                AppBarHMSBody: 'AI Integrated Health & Wellness Monitoring System - Using Genieland Core Technology...',
                AppBarMove: 'Omnidirectional Organization Resource and Tele-Rehabilitation Service Management Platform',
                AppBarMoveBody: 'Assist therapists to enhance interaction with clients, track performance progress and...',
                AppBarCustomize: 'Customize',
                AppBarCustomizeBody: 'Genieland is experienced on performing custom app development. And...',
                AppBarRehabilitation: 'to provide customized and cost-optimized apps in order to keep the...',
                AppBarHospitalBody: 'Mission on creating mobile apps for hospitals that assist and...',
                AppBarRehabilitationBody: 'By using AI and related technology, the company provides physical...',
                AppBarPoseEstimation: 'Human Pose Estimation & Behavior Analysis',
                AppBarPoseEstimationBody: 'ollect data through the camera of a mobile device (such as a smartphone)...',
                TechComingSoon: 'More Info will be published soon.',
                ComingSoon: 'More Info will be launched later. ',
                TechCard2_Title: 'GenieHMS'
            }
        },
        zh: {
            translation: {
                HMS: '身心靈訓練服務',
                Geniemove: '一元樂動',
                Customize: '客製化',
                welcome: '你好',
                Home: '主頁',
                AboutUs: '關於我們',
                Product: '我們的產品',
                Solutions: '應用程式解決方案',
                Contact: '聯絡我們',
                translationText: 'EN',
                Technology: '技術',
                Industry: '行業',
                Address: '地址',
                Email: '電郵',
                Phone: '聯絡電話',
                Getintouch: '聯繫方法',
                CompanyName: '一元精靈有限公司',
                CompanyAddress: '香港 九龍塘 達之路 72號創新中心 3樓 318A室',
                AboutUs_Text1:
                    '一元精靈有限公司透過人工智能及科技，提供協助不同機構與服務對象聯繫的身心健康方案、認知訓練、心理輔導等。一元精靈旨在提升大眾的自我健康管理意識，加強復康能力和預防慢性疾病。',
                AboutUs_Text2:
                    '我們主張整全性的途徑，指望從傳統模式邁向系統化的全方位組織資源及復康管理平台，旨在為機構提供增強和改善健康管理和遙距復健服務的解決方案，展望讓使用者「隨時隨地」進行培訓。 我們也致力於促進組織可以使用系統資源分析解決方案來有效地管理和分配其資源。',
                AboutUs_Text3:
                    '除了品牌產品/平台外，一元精靈還利用適當的新技術為機構提供客製化的應用程式開發服務，並擁有豐富的經驗服務中小企業、智慧城市提倡機構、醫院和復康服務行業。',
                Solutions_Title: '一元精靈 : 從事專業移動應用程式開發',
                Solutions_Part2: ' 一元精靈在執行定制應用程式開發方面擁有豐富的經驗。我們可以開發專門針對客戶業務需求量身定制的應用程式。',
                Solutions_Part2_Title: ' 客制化 ',
                SolutionsBody1_Title: '客制化',
                SolutionsBody1_Text:
                    '一元精靈執行實際應用學習，並使用其他新興技術開發APP，旨在促進和增強特定客戶現有解決方案或系統的功能。',
                SolutionsBody2_Titile: '離線雲',
                SolutionsBody2_Text:
                    '一元精靈在執行定制原生應用程式開發方面擁有豐富的經驗。我們可以開發專門針對客戶業務需求量身定制的應用程式。',

                LandingProduct: '我們的產品',
                LandingContact: '關於我們',
                LandingContactText: '透過提供一系列以人為本的人工智能及數碼科技的身心健康方案，致力協助.....',
                Genie_title: 'genie - 以人工智能伴你鍛煉身心',
                Genie_body1:
                    'genie針對缺乏運動的人士，以人工智能姿勢估算的技術，只是用手機的鏡頭，就可以收集和分析到用家的練習數據，然後提供漸進式報告和相應的回饋。',
                Genie_body2: '希望能夠幫助一些整天坐在辦公室或者缺乏運動的人多作舒展， 舒緩他們長期坐在座椅帶來的痛症。',
                Genie_BoxTilte1: '簡易',
                Genie_BoxBody1: '以影像和聲音構成的練習，簡短而有趣',
                Genie_BoxTilte2: '實時',
                Genie_BoxBody2: '即時測試你的動作',
                Genie_BoxsubBody2: '及姿勢',
                Genie_BoxTitle3: '獎賞',
                Genie_BoxBody3: '獎勵 genie 硬幣給自己作為每天能堅持運動 10 分鐘',
                Genie_BoxTitle4: '自我管理',
                Genie_BoxBody4: '透過報告及圖表，監察及管理身心靈健康狀況',
                Genie_Boxsub2Body2: '',
                Move_Tilte: '一元樂動網上互動平台為 “全方位組織資源及復康管理平台”',
                MoveTitle3: ' ',
                Move_Body1: '• 協助治療師可在遙距的情況下增強與服務對象的互動、追蹤進度及管理復康個案;',
                Move_Body2: '• 治療師可透過平台分配練習，並根據復康人士或長者的練習表現提供反饋，更可同時管理多個復康服務對象;',
                Move_Body3: '• 復康人士或長者可隨時隨地接收個人化的支援服務，保持身心健康;',
                Move_Body4: '• 遙距APP的多元化應用、可用於內部培訓或團隊建立活動;',
                Move_Body5: '• 適用於不同活動及多種用途管理，如 “器材操作與管理”及 “文件控制與管理”。',
                Move_BoxTitle1: '遙距',
                Move_BoxBody1: '提供訓練',
                Move_BoxTitle2: '專業平台',
                Move_BoxBody2: '管理日常操作',
                Move_BoxTitle3: '在家',
                Move_BoxBody3: '進行影片指導的訓練',
                Move_BoxTitle4: '追蹤',
                Move_BoxBody4: '練習進度及模式',
                Move_BoxTitle5: '組織資源管理',
                Move_BoxBody5: '系統化分析與組織資源管理',
                Move_BoxBody5_2: '管理組織資源',
                MoveTitle: ' 一元樂動',
                MoveTitle2: '全方位組織資源及復康管理平台',
                MoveBody1: '• 協助治療師可在遙距的情況下增強與服務對象的互動、追蹤進度及管理復康個案;',
                MoveBody2: '• 治療師可透過平台分配練習，並根據復康人士或長者的練習表現提供反饋,',
                MoveBody3: '更可同時管理多個復康服務對象;',
                MoveBody4: '• 復康人士或長者可隨時隨地接收個人化的支援服務，保持身心健康;',
                MoveBody5: '• 遙距APP的多元化應用、可用於內部培訓或團隊建立活動;',
                MoveBody6: '• 適用於不同活動及多種用途管理，如 “器材操作與管理”及 “文件控制與管理”。',
                More_info: '了解更多',
                HMSTitle: 'GenieHMS - 身心靈訓練服務',
                HMSBody1: '產品將於2023年第四季度推出',
                HMSBody2: '• 人工智能綜合健康與保健監測系統 - 使用一元核心技術',
                HMSBody3: '• 促進參與健身或復康斷練運動',
                HMSBody4: '• 能提供互動、在線或離線溝通平台',
                HMSBody5: '• 人工智能挑戰更能增加療程的趣味性和持續性',
                HMSBody6: '• 系就兼性強、iOS 和 Android 程式也能使用',
                HMSBody7: '• 互動智能運動站 一為安裝的中心增加智能活動設施，更可加強工作較率和人手調配',
                HMSBody8: '• 機構治療師及相關人員，能透過網絡系統，遙控管理使用者的進度、及中心的其他推廣活動。',
                HMSBody9: '• 此產品可與「一元樂動」平台聯動/連接。',
                MoreTech: '使用該技術的產品 :',
                More: '客制化的應用程式：',
                More2: '讓我們和您一起看世界。',
                CustomizeCard1_Title: '一元樂動(客製化)',
                CustomizeCard1_Body: '與不同機構合作及製作客製化內容...',
                CustomizeCard2_Title: '身心靈訓練服務(客製化)',
                CustomizeCard2_Body: '人工智能綜合健康與保健監測系統,支援離雲模式客制化功能...',
                CustomizeCard3_Title: '擁抱健康',
                CustomizeCard3_Body: '記錄健康狀況和認識自己的中醫體質，掌握自身健康變化...',
                IndustryBody: '醫管、醫護、醫療、復康服務行業應用程式開發的提供商',
                Industry1_Title: '非牟利機構',
                Industry1_Body:
                    '提供定制且成本優化的應用程式，以保持應用程式的成本效益。 這些應用程式將最適合每個非政府組織和社會團體運營的獨特性，其目標是為教育、醫療保健等領域提供服務。',
                Industry2_Title: '醫院',
                Industry2_Body:
                    '使命是為醫院創建移動應用程式，以幫助和解決以患者為中心的醫院的資源壓力，包括人力資源，並促進提高傳統住院系統的效率。',
                Industry2_Body2:
                    '我們知道為醫院創建成功的移動應用程式的關鍵是同理心。作為應用程式開發者，我們知道如何設計和開發醫院移動應用程式，並意識到如何讓用戶有信心和安全地使用定制解決方案。 並追求簡化提供者和患者之間的臨床溝通，以及改善醫院工作流程的管理。',
                Industry3_Title: '復康服務',
                Industry3_Body:
                    '公司利用人工智能及相關技術，提供身心健康解決方案，協助康復機構與服務患者建立聯繫，旨在提升公眾自我健康管理意識，增強康復能力，預防慢性病。',
                Industry3_Body2: '協助治療師通過使用移動應用程式加強與患者的互動、進度跟踪和管理。 促進組織的資源管理。',
                Technology_title: '一元核心技術 - 智能姿勢估算及行為分析',
                Technology_P2_Title: '智能姿勢估算及行為分析',
                Technology_P2_Body:
                    '智能姿勢估算（Human Pose Estimation）及行為分析（Behavior Analysis）是Genieland從2017年就開始研發、並已獲得專利 的人工智能技術，市面上傳統的應用程式甚少運用到。它是一種計算機視覺算法，只須通過移動設備（如智能手機）的攝像頭收集數據，透過圖像及影片檢測人體資料，估算 17個人體重要關節位 (例如：眼, 耳朵, 肩膀…)，而每個重要關節位，有相應的分數，其精準度大於85% 。從練習獲得資料配合綜合報告，可計算用戶的姿態、維持的時間及平行力，以多角度分析，然後透過我們的領域知識圖提供漸進式綜合報告和相應的反饋，便可引導用家進行其個人的日常練習。',
                Technology_Title: 'Genieland Core Technology',
                Technology_P2_Body2: ' ',
                AppBarGenie: '人工智能運動程式',
                AppBarGenieBody: 'genie針對缺乏運動的人士，以人工智能姿勢估算的技術...',
                AppBarHMS: '身心靈訓練服務',
                AppBarHMSBody: '人工智能綜合健康與保健監測系統 - 使用一元核心技術...',
                AppBarMove: '全方位組織資源及復康管理平台',
                AppBarMoveBody: '協助治療師可在遙距的情況下增強與服務對象的互動、追蹤進度及...',
                AppBarCustomize: '客制化',
                AppBarCustomizeBody: '我們可以開發專門針對客戶業務需求量身定制的應用程式...',
                AppBarRehabilitation: '提供定制且成本優化的應用程式，以保持應用程式的成本...',
                AppBarHospitalBody: '使命是為醫院創建移動應用程式，以幫助和解決以患者為中心的...',
                AppBarRehabilitationBody: '公司利用人工智能及相關技術，提供身心健康解決方案，協助康...',
                AppBarPoseEstimation: '智能姿勢估算及行為分析',
                AppBarPoseEstimationBody:
                    '透過圖像及影片檢測人體資料，估算 17個人體重要關節位 (例如：眼, 耳朵, 肩膀…)，而每個重要關節位...',
                TechComingSoon: '更多資訊即將發佈',
                ComingSoon: '更多資訊將在稍後推出',
                TechCard2_Title: '身心靈訓練服務'
            }
        }
    },
    lng: 'zh',
    fallbackLng: 'zh',
    interpolation: {
        escapeValue: false
    }
});

export default i18next;
