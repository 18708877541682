import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const Home = Loadable(lazy(() => import('views/pages/landing')));
const ContactUs = Loadable(lazy(() => import('views/pages/contact-us')));
const AboutUs = Loadable(lazy(() => import('views/pages/about-us')));
const Genie = Loadable(lazy(() => import('views/pages/Product/Genie')));
const HMS = Loadable(lazy(() => import('views/pages/Product/HMS')));
const Move = Loadable(lazy(() => import('views/pages/Product/Move')));
const Solutions = Loadable(lazy(() => import('views/pages/Product/solutions')));
const Industry = Loadable(lazy(() => import('views/pages/Industry')));
const Technology = Loadable(lazy(() => import('views/pages/Technology')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/home',
            element: <Home />
        },
        {
            path: '/contact-us',
            element: <ContactUs />
        },
        {
            path: '/about-us',
            element: <AboutUs />
        },
        {
            path: '/product/Genie',
            element: <Genie />
        },
        {
            path: '/product/HMS',
            element: <HMS />
        },
        {
            path: '/product/Move',
            element: <Move />
        },
        {
            path: '/product/Solutions',
            element: <Solutions />
        },
        {
            path: '/Industry',
            element: <Industry />
        },
        {
            path: '/Technology',
            element: <Technology />
        }
    ]
};

export default MainRoutes;
